<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="getData.name !== undefined">
            <v-text-field
              outlined
              label="Nomi"
              dense
              v-model="getData.name"
            ></v-text-field>
          </div>
          <div v-if="getDataName !== undefined">
            <v-combobox
              v-model="main_type2"
              :items="allMainExpenditures"
              label="Asosiy Xarajatlar"
              item-text="name"
              return-object
              outlined
              dense
            ></v-combobox>
          </div>
          <v-text-field
            outlined
            label="Kod"
            dense
            v-model="getData.code"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Yopish</v-btn
          >
          <v-btn color="blue darken-1" text @click="submitUpdate"
            >Saqlash</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data2: {},
      data3: this.actionObj,
      main_type: '',
      main_type2: ''
    }
  },
  created() {
    this.$store.dispatch('allBasicCost')
  },
  watch: {
    getDataName: function (val) {
      if (val.name !== undefined) {
        this.main_type2 = val.name
      }
    }
  },
  computed: {
    getData() {
      const data3 = {
        name: this.actionObj.name,
        code: this.actionObj.code,
        main_type: this.actionObj.main_type
      }
      return data3
    },
    getDataName() {
      const data = this.$store.state.expenses.allBasicCost.find(
        (obj) => obj.id == this.actionObj.main_type
      )
      return data
      // return newobj;
    },
    allMainExpenditures() {
      return this.$store.state.expenses.allBasicCost
    }
  },
  methods: {
    submitUpdate() {
      const id = {
        id: this.actionObj.id
      }
      const data = {
        name: this.getData.name,
        code: this.getData.code
      }
      if (this.main_type2.hasOwnProperty('name') == false) {
        data.main_type = this.getData.main_type
      } else if (this.main_type2.hasOwnProperty('name') == true) {
        data.main_type = this.main_type2.id
      }
      this.$store.dispatch('updateCostCategories', { id, data })
      this.dialog = false
    }
  },
  props: {
    actionObj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
